<template>
  <div style="padding: 20px; text-align: left;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div v-if="page === 1">
      <el-select style="margin-right: 10px;"
        v-model="client"
        placeholder="請選擇品牌"
        @change="getClient">
        <el-option v-for="item in clientList"
          :key="item.clientId"
          :label="item.name"
          :value="item.clientId">
        </el-option>
      </el-select>
      <!-- table -->
      <div id="table">
        <mu-data-table height="calc(100vh - 250px)"
          style="width: 100%;"
          :data="formList"
          :no-data-text="emptyText"
          :columns="columns">
          <template slot-scope="scope">
            <td>{{ scope.row.title }}</td>
            <td>{{ scope.row.id }}</td>
            <td>{{ getDuration(scope.row) }}</td>
            <td>
              <el-button type="info" plain
                icon="el-icon-copy-document"
                size="small"
                style="margin-bottom: 0 !important;"
                @click="copyForm(scope.row)">
              </el-button>
              <el-button type="primary" plain
                size="small"
                style="margin-bottom: 0 !important;"
                @click="editForm(scope.row)">
                編輯
              </el-button>
              <el-button type="danger" plain
                @click="checkDelForm(scope.row.id)"
                size="small"
                style="margin: 0 !important;">
                刪除
              </el-button>
            </td>
          </template>
        </mu-data-table>
        <div style="text-align: right;">
          <el-button style="margin: 0 !important;"
            :disabled="!client"
            @click="addForm"
            icon="el-icon-plus"
            type="primary"
            plain>
            新增問卷
          </el-button>
        </div>
      </div>
      <mu-dialog :open="deleteDia" width="400">
        <div>確定要刪除這個表單嗎？</div>
        <mu-button slot="actions" flat color="primary" @click="deleteDia = false">取消</mu-button>
        <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
      </mu-dialog>
    </div>
    <!-- 編輯問卷 -->
    <div v-if="page === 2">
      <formSetting
        @goBack="goBack"
        @saveForm="saveForm"
        :clientId="client"
        :formInfo="formInfo"
        :itemList="itemList"
        :editType="editType"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import formSetting from './components/formSetting.vue';

const defaultContent = {
  id: '',
  title: '',
  award: '1',
  nowSerial: 0,
  awardPoint: 0,
  awardCoupon: '',
  coverImg: '',
  welcomeText: '',
  userLimitOpen: true,
  userLimit: 1,
  durationLimit: false,
  duration: [Date.now(), Date.now()],
  thanksCoverImg: '',
  thanksTitle: '你已完成問卷填寫',
  thanksDesc: '親愛的貴賓你好，感謝您填寫本問卷，我們會依據您的建議，提供更好的服務給您，期待您下次的到來！',
  thanksLinkOpen: false,
  thanksBtnLink: '',
  thanksBtnLabel: '',
  liffId: '',
  isBroadcast: false,
  msgType: 'message',
  message: '',
  questions: []
};

export default {
  data() {
    return {
      pageLoading: false,
      client: '',
      page: 1,
      emptyText: '暫無資料',
      columns: [
        { title: '問卷標題', name: 'title' },
        { title: '問卷編號', name: 'id' },
        { title: '問卷開放時間', name: 'duration' },
        { title: '操作', name: 'edit', width: 230 }
      ],
      itemList: [],
      formList: [],
      formInfo: null,
      editType: 'add',
      deleteId: '',
      deleteDia: false
    }
  },
  mounted() {
    if (this.clientList && this.clientList.length > 0) {
      // do something
    }
    else {
      this.getBrands();
    }
  },
  components: {
    formSetting
  },
  computed: {
    ...mapGetters({
      clientList: 'clients'
    }),
  },
  methods: {
    ...mapActions({
      getBrands: 'getBrands',
      getForms: 'getForms',
      removeForm: 'removeForm',
      updateForm: 'updateForm',
      getItemList: 'getItemList'
    }),
    goBack(route) {
      this.page = route;
    },
    getFormList() {
      this.getForms({ clientId: this.client })
        .then((_res) => {
          this.emptyText = '暫無資料';
          this.pageLoading = false;
          this.formList = _res.result;
        })
    },
    saveForm(info) {
      const postData = {
        clientId: this.client,
        formId: info.id,
        info: info,
      };
      this.page = 1;
      this.pageLoading = true;
      this.updateForm(postData)
        .then(() => {
          this.getFormList();
        })
    },
    getClient() {
      this.formList = [];
      this.emptyText = '載入中...';
      // 取得表單
      this.getFormList();
      // 取得品項
      this.getItemList({ clientId: this.client, enviro: 'pro' })
        .then((_res) => {
          if (_res.itemData) {
            let list = [];
            if (_res.itemData.list) {
              for (let i = 0; i < _res.itemData.list.length; i += 1) {
                list.push({
                  itemNo: _res.itemData.list[i].itemNo,
                  title: _res.itemData.list[i].title
                })
              }
            }
            this.itemList = list;
          }
        })
    },
    checkDelForm(id) {
      this.deleteId = id;
      this.deleteDia = true;
    },
    sendDelete() {
      this.deleteDia = false;
      const postData = {
        clientId: this.client,
        formId: this.deleteId
      };
      this.pageLoading = true;
      this.removeForm(postData)
        .then(() => {
          this.getFormList();
        })
    },
    getDuration(row) {
      if (row.durationLimit && row.duration && row.duration[0]) {
        const start = new Date(row.duration[0]).toLocaleDateString();
        const end = new Date(row.duration[1]).toLocaleDateString();
        return `${start} - ${end}`;
      }
      else {
        return '未設定';
      }
    },
    addForm() {
      this.formInfo = JSON.parse(JSON.stringify(defaultContent));
      this.editType = 'add';
      this.page = 2;
    },
    editForm(row) {
      this.formInfo = JSON.parse(JSON.stringify(row));
      this.editType = 'edit';
      this.page = 2;
    },
    copyForm(row) {
      this.formInfo = JSON.parse(JSON.stringify(row));
      this.formInfo.id = '';
      this.editType = 'add';
      this.page = 2;
    }
  }
}

</script>

<style scoped>

</style>